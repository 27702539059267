import { ChangeEvent, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { FixedArrowButton } from 'components/button/FixedArrowButton';
import { calculateFee } from 'utils/payments';
import { useHtmlParser } from 'hooks/useHtmlParser';
import tw from 'tailwind-styled-components';
export interface DonationOptionResponse {
  amount: number;
  recurring: boolean;
  frequency?: string;
  coverFees?: boolean;
  campaignId?: string;
}
export interface DonationOptionsProps {
  save: (props: DonationOptionResponse) => void;
  donationOptions?: string[];
  message?: string;
  donationMessage?: string;
  ctaColor?: string;
}

interface PriceOptionProps {
  selected: boolean;
  custom?: string;
}

const PriceOption = tw.button`
  rounded-md
  items-center
  border-2
  border-trhp-teal
  py-6
  px-2.5
  w-full
  hover:bg-trhp-blue
  font-medium
  ${(props: PriceOptionProps) =>
    props.selected ? 'bg-white text-trhp-teal' : `bg-trhp-teal text-white`}
  ${(props: PriceOptionProps) =>
    props.custom ? 'text-2xl  py-[1.65rem]' : 'text-3xl'}
`;

type StyledFieldProps = {
  showerror?: string;
};
const StyledFieldWrapper = tw.div<StyledFieldProps>`
${(props: StyledFieldProps) =>
  props.showerror
    ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500'
    : 'border-trhp-gray focus:ring-trhp-teal'}
  relative border rounded-md shadow-inner w-full py-5 px-6 items-center flex my-6
`;

export const DonationOptions = (props: DonationOptionsProps) => {
  const [selectedAmount, setSelectedAmount] = useState<string>('250');
  const [amount, setAmount] = useState('250');
  const [coverFees, setCoverFees] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [transactionFee, setTransactionFee] = useState(calculateFee(250));

  const isValidAmount = (amount: string) => {
    return !isNaN(parseFloat(amount));
  };

  const updateAmount = (ev: ChangeEvent<HTMLInputElement>) => {
    setAmount(ev.target.value);
    if (selectedAmount !== 'custom') setSelectedAmount('custom');
    setTransactionFee(calculateFee(parseFloat(ev.target.value)));
  };

  const selectPrice = (price: string) => {
    setPriceError(false);
    if (price === 'custom') {
      setAmount('');
    } else {
      setAmount(price);
      setTransactionFee(calculateFee(parseFloat(price)));
    }
    setSelectedAmount(price);
  };

  const handleSave = () => {
    if (!isValidAmount(amount)) {
      setPriceError(true);
      return;
    }
    props.save({
      amount: parseFloat(amount),
      recurring: recurring,
      frequency: 'monthly',
      coverFees: coverFees
    });
  };

  const priceOpts = props.donationOptions
    ? props.donationOptions
    : ['500', '250', '100'];

  const priceOptions = priceOpts.map(price => (
    <div className="float-left" key={price}>
      <PriceOption
        selected={selectedAmount === price}
        onClick={() => selectPrice(price)}
        style={{
          ...(props.ctaColor && {
            borderColor: props.ctaColor,
            color: selectedAmount === price ? 'white' : props.ctaColor,
            backgroundColor:
              selectedAmount === price ? props.ctaColor : 'inherit'
          })
        }}
      >
        <span className="text-sm">
          <sup className="top-[-1em] pr-1.5">$</sup>
        </span>
        {price}
      </PriceOption>
    </div>
  ));
  const message = useHtmlParser(
    props.message ??
      'Every gift goes directly toward our mission of a forever family for every child.'
  );
  const hasDonationMessage = typeof props.donationMessage !== 'undefined';
  const donationMessage = useHtmlParser(props.donationMessage ?? '');
  return (
    <div className="px-6">
      <p className="text-center w-full text-trhp-gray my-6">{message}</p>
      <StyledFieldWrapper showerror={priceError ? 'true' : undefined}>
        <span className="border-dotted border-r border-[#aaa] pr-6 text-lg">
          $
        </span>
        <input
          type="text"
          value={amount}
          name="amount"
          onChange={updateAmount}
          className="text-4xl font-medium px-2 text-center w-40 outline-0 focus:ring-transparent border-none"
        />
        {priceError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </StyledFieldWrapper>
      {hasDonationMessage && (
        <div className="w-full my-6">{donationMessage}</div>
      )}
      <div className="grid grid-cols-2 gap-4 mb-7">
        {priceOptions}
        <div className="float-left">
          <PriceOption
            custom={'true'}
            selected={selectedAmount === 'custom'}
            onClick={() => selectPrice('custom')}
            style={{
              ...(props.ctaColor && {
                borderColor: props.ctaColor,
                color: selectedAmount === 'custom' ? 'white' : props.ctaColor,
                backgroundColor:
                  selectedAmount === 'custom' ? props.ctaColor : 'inherit'
              })
            }}
          >
            Custom Amount
          </PriceOption>
        </div>
      </div>
      <div className="border rounded-md shadow bg-white p-4 hover:border-trhp-teal mb-7">
        <input
          id="recurring"
          aria-describedby="checkbox-description"
          name="recurring"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-trhp-teal focus:ring-transparent"
          checked={recurring}
          onChange={e => setRecurring(e.target.checked)}
        />
        <label htmlFor="recurring" className="pl-4 w-11/12 inline-block">
          Join the &ldquo;studio&rdquo; by making your gift monthly!
        </label>
      </div>
      <div className="border flex rounded-md shadow bg-white p-4 hover:border-trhp-teal mb-7 items-center">
        <input
          id="coverFees"
          aria-describedby="checkbox-description"
          name="coverFees"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-trhp-teal focus:ring-transparent"
          checked={coverFees}
          onChange={e => setCoverFees(e.target.checked)}
        />
        <label htmlFor="coverFees" className="pl-4 w-11/12 inline-block">
          I&apos;d like to help cover the transaction fees of ${transactionFee}{' '}
          for my donation.
        </label>
      </div>

      <div className="text-center">
        <FixedArrowButton
          onClick={handleSave}
          styletype="primary"
          label="Continue"
          className="rounded-md py-3 px-6 font-medium w-60 mb-7 text-2xl block mx-auto"
          bgOverrideColor={props.ctaColor}
        />
      </div>
    </div>
  );
};
