import * as yup from 'yup';
import { AnimatePresence } from 'framer-motion';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { ComponentTypes } from 'common/ComponentTypes';
import { Cta, CtaFormSchema, CtaProps, CtaSchema } from './Cta';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { SubscriptionForm } from 'components/SubscriptionForm';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utils/string';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

export interface PopupProps extends BasicBlockProps {
  title: string;
  message: string;
  cta?: CtaProps;
  openDelay: number;
  fullscreen?: boolean;
  includeSubscriptionForm?: boolean;
  subscriptionFormBackground?: string;
}

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  title: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: true
  },
  message: {
    type: ComponentTypes.TextField,
    label: 'Message',
    required: false
  },
  cta: CtaFormSchema,
  openDelay: {
    type: ComponentTypes.TextField,
    label: 'Open Delay (in milliseconds)',
    required: false,
    additionalProps: {
      type: 'number'
    }
  },
  fullscreen: {
    type: ComponentTypes.Toggle,
    label: 'Fullscreen Popup',
    required: false
  },
  includeSubscriptionForm: {
    type: ComponentTypes.Toggle,
    label: 'Include Subscription Form',
    required: false
  },
  subscriptionFormBackground: {
    type: ComponentTypes.ColorPicker,
    label: 'Subscription Form Background Color'
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  title: yup.string().required(),
  message: yup.string().required(),
  cta: CtaSchema.notRequired(),
  openDelay: yup.string(),
  fullscreen: yup.boolean(),
  includeSubscriptionForm: yup.boolean(),
  subscriptionFormBackground: yup.string()
});

interface FullScreenPopupProps extends PopupProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function FullScreenPopup(props: FullScreenPopupProps) {
  const {
    title,
    message,
    cta,
    textColor,
    backgroundColor,
    includeSubscriptionForm,
    subscriptionFormBackground,
    open,
    setOpen
  } = props;

  const showCta = cta && cta.label && cta.url;
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-800/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            style={{ background: backgroundColor, color: textColor }}
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="text-gray-400 hover:text-gray-500 bg-black rounded-full border-white border-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>

            <div className="relative mx-auto w-full rounded p-10">
              <div className="mt-3 py-6 text-center sm:mt-0 sm:text-left">
                <h3 className="uppercase text-[40px] font-semibold text-center leading-[2.5rem]">
                  {title}
                </h3>
              </div>
              <div className="w-full text-center overflow-hidden px-4 pb-8">
                <div className="w-full pt-[25px]">{message}</div>
                {showCta && (
                  <div className="flex flex-shrink-0 items-center my-4 justify-items-center justify-center">
                    <Cta {...cta} />
                  </div>
                )}
                {includeSubscriptionForm && (
                  <div
                    className="flex flex-shrink-0 items-center my-4 p-4 justify-items-center justify-center"
                    style={{
                      backgroundColor: subscriptionFormBackground ?? '#ffffff'
                    }}
                  >
                    <SubscriptionForm />
                  </div>
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

const PopUp = (props: PopupProps) => {
  const {
    title,
    message,
    cta,
    openDelay,
    textColor,
    backgroundColor,
    fullscreen,
    includeSubscriptionForm,
    subscriptionFormBackground
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [timer, setTimer] = useState<any>(null);

  const StyledDiv = styled.div`
    box-shadow: 0px 0px 30px 0px rgba(2, 2, 2, 1);
  `;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimer(setTimeout(() => setOpen(true), openDelay));
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  if (fullscreen) {
    return <FullScreenPopup {...props} open={open} setOpen={setOpen} />;
  }
  const showCta = cta && cta.label && cta.url;
  return (
    <AnimatePresence>
      {open && (
        <div className={'visible relative z-50 shadow-2xl'}>
          <StyledDiv
            className={classNames(
              'fixed bg-white w-[300px] h-[auto] right-[2vw] p-[18px]rounded border-8 border-solid border-black flex items-center justify-center transition ease-in duration-500',
              open ? 'bottom-[0vh] opacity-1' : '-bottom-[521px] opacity-0'
            )}
            style={{ background: backgroundColor, color: textColor }}
          >
            <div className="relative mx-auto w-full rounded p-10">
              <div className="mt-3 pt-6 text-center sm:mt-0 sm:text-left">
                <h3 className="uppercase text-[40px] font-semibold text-center leading-[2.5rem]">
                  {title}
                </h3>
              </div>
              <button
                type="button"
                className="absolute -right-4 -top-4 text-gray-400 hover:text-gray-500 bg-black rounded-full border-white border-2"
                onClick={handleClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="w-full text-center bg-white overflow-hidden px-4 pb-8">
                <div className="w-full pt-[25px]">{message}</div>
                {showCta && (
                  <div className="flex flex-shrink-0 items-center my-4 justify-items-center justify-center">
                    <Cta {...cta} />
                  </div>
                )}
                {includeSubscriptionForm && (
                  <div
                    className="flex flex-shrink-0 items-center my-4 p-4 justify-items-center justify-center"
                    style={{
                      backgroundColor: subscriptionFormBackground ?? '#ffffff'
                    }}
                  >
                    <SubscriptionForm />
                  </div>
                )}
              </div>
            </div>
          </StyledDiv>
        </div>
      )}
    </AnimatePresence>
  );
};

export default PopUp;
