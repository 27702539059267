import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { useHtmlParser } from 'hooks/useHtmlParser';

import tw from 'tailwind-styled-components';

export interface CollapsibleItemProps {
  label: string;
  content: string;
  defaultOpen?: boolean;
  onOpen?: () => void;
  groupref?: any;
}
interface CollapsibleWrapperProps {
  open: boolean;
}
const CollapsibleWrapper = tw.div`
  ${(props: CollapsibleWrapperProps) =>
    props.open
      ? ' text-trhp-gray bg-white'
      : 'bg-trhp-off-white text-trhp-text-gray'}
  p-2
  border
  bordr-trhp-gray
`;

const CollapsibleItem = (props: CollapsibleItemProps) => {
  const { label, content, defaultOpen, onOpen, groupref } = props;
  const parsedBody = useHtmlParser(content || '');

  return (
    <div className="w-full">
      <div className="mx-auto w-full">
        <Disclosure defaultOpen={defaultOpen}>
          {({ open }) => {
            return (
              <CollapsibleWrapper open={open}>
                <DisclosureButton
                  ref={groupref}
                  data-open={open}
                  className="uppercase flex w-full justify-between px-4 py-2 text-left text-lg font-medium focus:outline-none focus-visible:ring font-josefin"
                  onClick={() => {
                    if (onOpen) onOpen();
                  }}
                >
                  {label}
                  {!open ? (
                    <PlusCircleIcon className="h-5 w-5 float-right fill-trhp-icon-gray" />
                  ) : (
                    <MinusCircleIcon className="h-5 w-5 float-right fill-trhp-icon-gray" />
                  )}
                </DisclosureButton>

                <DisclosurePanel
                  transition
                  className="transition-all duration-100 ease-in overflow-hidden px-4 pt-4 pb-2 text-sm text-trhp-gray leading-8 body-text data-[enter]:scale-100 data-[enter]:opacity-100 data-[leave]:duration-100 data-[leave]:data-[closed]:opacity-0 data-[leave]:data-[closed]:scale-95"
                >
                  {parsedBody}
                </DisclosurePanel>
              </CollapsibleWrapper>
            );
          }}
        </Disclosure>
      </div>
    </div>
  );
};

export default CollapsibleItem;
