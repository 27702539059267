import { TfiAngleRight } from 'react-icons/tfi';
import { createSubmission } from 'services/submissions';
import { useState } from 'react';

export const SubscriptionForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [honey, setHoney] = useState('');
  const [subscribeMsg, setSubscribeMsg] = useState<string | false>(false);

  const subscribe = async (e: any) => {
    e.preventDefault();
    if (honey !== '') return;
    if (name !== '' && email !== '') {
      const saved = await createSubmission({
        formName: 'newsletter-subscribe',
        mailchimpList: '8eac5424a4',
        data: JSON.stringify({ name, email })
      });
      if (saved) {
        setSubscribeMsg('Thank you for subscribing!');
        setName('');
        setEmail('');
        setTimeout(() => {
          setSubscribeMsg(false);
        }, 5000);
      } else {
        setSubscribeMsg(
          'There was an error subscribing you. Please try again.'
        );
      }
    }
  };

  return (
    <form
      className="mt-6 sm:max-w-md"
      method="post"
      onSubmit={subscribe}
      id="subscribe-to-newsletter"
    >
      <label htmlFor="email-address" className="sr-only">
        Email address
      </label>
      <input
        type="text"
        name="name"
        id="name"
        value={name}
        autoComplete="name"
        required
        className="w-full min-w-0 appearance-none py-3.5 border-0 bg-white px-3 py-1.5 text-base text-trhp-gray shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-64 sm:text-sm sm:leading-6 xl:w-full mb-5"
        placeholder="Name"
        onChange={e => setName(e.target.value)}
      />
      <input
        type="text"
        name="title"
        id="title"
        className="hidden"
        onChange={e => setHoney(e.target.value)}
      />
      <input
        type="email"
        name="email"
        id="email"
        value={email}
        autoComplete="email"
        required
        className="w-full min-w-0 appearance-none py-3.5 border-0 bg-white px-3 py-1.5 text-base text-trhp-gray shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
        placeholder="Email"
        onChange={e => setEmail(e.target.value)}
      />
      <div className="mt-4 sm:mt-0">
        <button
          type="submit"
          className="group mt-5
                  block
                  w-full
                  text-center
                  font-normal
                  border
                  text-xl
                  uppercase
                  px-4
                  py-2
                border-trhp-teal
                  bg-transparent
                hover:bg-trhp-teal
                hover:text-white
                  hover:pr-5
                text-trhp-teal
                transition-all
                ease-in delay-100"
          onClick={subscribe}
        >
          Subscribe
          <span className="opacity-0 group-hover:opacity-100 w-1 pl-1 transition-all ease-in delay-100 text-base">
            <TfiAngleRight className="inline" style={{ marginTop: '-5px' }} />
          </span>
        </button>
        {subscribeMsg && (
          <p className="text-white text-lg mt-2">{subscribeMsg}</p>
        )}
      </div>
    </form>
  );
};
