import * as yup from 'yup';
import { AiTwotoneLock } from 'react-icons/ai';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { DonationForm } from 'components/give/DonationForm';
import {
  DonationOptionResponse,
  DonationOptions
} from 'components/give/DonationOptions';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { TfiAngleLeft } from 'react-icons/tfi';
import { Transition } from '@headlessui/react';
import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import ResponsiveImage from './ResponsiveImage';
import currency from 'currency.js';
import dynamic from 'next/dynamic';

const ThankYou = dynamic(() => import('components/give/ThankYou'), {
  ssr: false
});

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  backgroundImage: {
    type: ComponentTypes.FileUpload,
    label: 'Background Image',
    required: false,
    additionalProps: {
      autoUpload: true,
      maxFiles: 1,
      tooltip: true,
      allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
    }
  },
  message: {
    type: ComponentTypes.RichTextEditor,
    label: 'Welcome Copy'
  },
  thankYouMessage: {
    type: ComponentTypes.RichTextEditor,
    label: 'Thank You Message'
  },
  donationMessage: {
    type: ComponentTypes.RichTextEditor,
    label: 'Donation Message'
  },
  donationOptions: {
    type: ComponentTypes.Array,
    label: 'Donation Options',
    additionalProps: {
      itemSchema: {
        amount: {
          type: ComponentTypes.TextField,
          label: 'Amount',
          required: true
        }
      }
    }
  },
  showTitle: {
    type: ComponentTypes.Toggle,
    label: 'Show Title',
    required: true
  },
  showProgress: {
    type: ComponentTypes.Toggle,
    label: 'Show Progress',
    required: true
  },
  progressBackgroundColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Progress Background Color',
    required: false
  },
  progressColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Progress Bar Color',
    required: false
  },
  ctaColor: {
    type: ComponentTypes.ColorPicker,
    label: 'CTA Color',
    required: false
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  backgroundImage: yup.string().notRequired(),
  message: yup.string().notRequired(),
  thankYouMessage: yup.string().notRequired(),
  donationMessage: yup.string().notRequired().nullable(),
  donationOptions: yup
    .array()
    .of(
      yup.object({
        amount: yup.string().required()
      })
    )
    .notRequired(),
  showProgress: yup.boolean().required(),
  progressBackgroundColor: yup.string().notRequired(),
  progressColor: yup.string().notRequired(),
  ctaColor: yup.string().notRequired(),
  showTitle: yup.boolean().required()
});

export interface GiveBlockProps extends BasicBlockProps {
  backgroundImage: string;
  thankYouMessage: string;
  donationMessage?: string;
  message?: string;
  id?: string;
  donationOptions: { amount: string }[];
  paymentIntent?: string;
  clientSecret?: string;
  name?: string;
  showProgress?: boolean;
  showTitle?: boolean;
  goal?: number;
  totalRaised?: number;
  progressBackgroundColor: string;
  progressColor: string;
  campaignId?: string;
  slug?: string;
  enableMatching?: boolean;
  matchingMessage?: string;
  matchingLimit?: number;
  ctaColor?: string;
}

const Give = (props: GiveBlockProps) => {
  const router = useRouter();

  if (!router.isReady) return <LoadingSpinner />;
  const { payment_intent, payment_intent_client_secret, id } = router.query;

  const {
    backgroundImage,
    message,
    donationOptions,
    thankYouMessage,
    name,
    goal,
    totalRaised,
    showProgress,
    progressBackgroundColor,
    progressColor,
    campaignId,
    slug,
    showTitle,
    enableMatching,
    matchingMessage,
    matchingLimit,
    donationMessage,
    ctaColor
  } = props;
  const blockRef = useRef<HTMLDivElement>(null);
  const [stepTitle, setStepTitle] = useState('');
  const [step, setStep] = useState(0);
  const [data, setData] = useState<DonationOptionResponse | null>(null);
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    (payment_intent_client_secret as string) ?? undefined
  );

  const isBrowser = () => typeof window !== 'undefined'; //The approach recommended by Next.js

  const scrollToTop = () => {
    if (!isBrowser()) return;
    blockRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const gotoStepTwo = (options: DonationOptionResponse) => {
    if (
      data &&
      (data.amount !== options.amount ||
        data.coverFees !== options.coverFees ||
        data.frequency !== options.frequency ||
        data.recurring !== options.recurring)
    ) {
      setClientSecret(undefined);
    }
    setData({
      ...data,
      ...options,
      campaignId
    });
    setStep(1);
    setStepTitle('Add Your Information');
    scrollToTop();
  };
  const goBack = () => {
    const newStep = step - 1;
    setStep(newStep);
    if (newStep === 0) {
      setStepTitle('');
    } else if (newStep === 1) {
      setStepTitle('Add Your Information');
    }
    scrollToTop();
  };

  const isThankYou = id && payment_intent && clientSecret;
  const backgroundColor = progressBackgroundColor || '#e2e8f0';
  const barColor = progressColor || '#1e365c';
  const roundNum = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };
  let pct = totalRaised && goal ? roundNum((totalRaised / goal) * 100) : 0;
  if (pct > 100) pct = 100;

  const hasGoal = goal && goal > 0;

  let thankYouText = thankYouMessage;
  if (enableMatching && matchingMessage) {
    if (matchingLimit) {
      if (totalRaised && totalRaised <= matchingLimit) {
        thankYouText = matchingMessage;
      }
    }
  }
  return (
    <BlockWrapper {...props}>
      {backgroundImage && (
        <div className="absolute t-0 l-0 z-0 min-h-[400px] w-full">
          <ResponsiveImage url={backgroundImage} alt={''} />
        </div>
      )}
      <div className="flex min-h-full flex-col relative z-0 justify-center items-center">
        <div className="w-full max-w-7xl sm:w-2/4">
          {showTitle && (
            <h1 className="text-center uppercase text-2xl lg:text-[3.5rem] lg:leading-[3.5rem] font-normal pb-12">
              {name}
            </h1>
          )}
          {showProgress && (
            <>
              <div className="relative w-full">
                <div className="mb-1 text-left float-left">
                  <p className="text-2xl font-semibold mb-0">
                    {currency(totalRaised ?? 0).format()}
                  </p>
                  {hasGoal && (
                    <p className="mt-0 flex items-baseline text-sm font-semibold opacity-50">
                      of {currency(goal).format()}
                    </p>
                  )}
                </div>
                {hasGoal && (
                  <div className="mb-1 text-right float-right">
                    <p className="text-2xl font-semibold mb-0">{pct}%</p>
                    <p className="mt-0 flex items-baseline text-sm font-semibold opacity-50">
                      of Goal
                    </p>
                  </div>
                )}
                <div className="clear-both"></div>
              </div>
              {hasGoal && (
                <div
                  className="w-full h-6 rounded-full flex-grow"
                  style={{ backgroundColor: backgroundColor }}
                >
                  <div
                    className="h-6 bg-blue-600 rounded-full dark:bg-blue-500"
                    style={{ backgroundColor: barColor, width: `${pct}%` }}
                  ></div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div
        className="flex min-h-full flex-col justify-center items-center p-0 relative z-0 pt-20 text-trhp-gray"
        ref={blockRef}
      >
        <div className="bg-white w-full max-w-xl sm:w-2/4">
          <div className="sm:mx-auto">
            <div className="border-b border-gray-200 bg-gray-100 text-center py-4  h-12 relative text-base">
              {step !== 0 && (
                <button
                  onClick={goBack}
                  className="absolute px-5 left-0 top-0 text-xs h-full hover:bg-gray-200"
                >
                  <TfiAngleLeft />
                </button>
              )}
              <div className="block -mt-1">{stepTitle}</div>
            </div>
            <div className="pt-4 bg-white overflow-hidden">
              {!isThankYou ? (
                <>
                  <Transition
                    as="div"
                    show={step === 0}
                    enter="transform transition ease-out duration-50 sm:duration-700"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease duration-50 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <DonationOptions
                      save={gotoStepTwo}
                      donationOptions={donationOptions.map(opt => opt.amount)}
                      message={message}
                      donationMessage={donationMessage}
                      ctaColor={ctaColor}
                    />
                  </Transition>

                  <Transition
                    as="div"
                    show={step === 1}
                    enter="transform transition delay-500 ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <DonationForm
                      data={data!}
                      goBack={goBack}
                      clientSecret={clientSecret}
                      setClientSecret={setClientSecret}
                      slug={slug}
                      ctaColor={ctaColor}
                    />
                  </Transition>
                </>
              ) : (
                <ThankYou
                  id={id as string}
                  paymentIntent={payment_intent as string}
                  clientSecret={payment_intent_client_secret as string}
                  message={thankYouText}
                />
              )}
            </div>
            <div className="border-t border-gray-200 bg-gray-100 text-center py-6 relative text-sm text-trhp-gray-100">
              <AiTwotoneLock className="inline pr-1" />
              Secure Donation
            </div>
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};
export default Give;
